import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "IssuedBill",
  },
  // 结算管理--应收管理
  // 已出结算单列表
  {
    path: "/issuedBill",
    name: "IssuedBill",
    component: () =>
      import(
        /* webpackChunkName: "issuedBill" */
        "../views/settlementManagement/receivableManagement/issuedBill.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // 帐单审核列表
  {
    path: "/billAudit",
    name: "BillAudit",
    component: () =>
      import(
        /* webpackChunkName: "issuedBill" */
        "../views/settlementManagement/receivableManagement/billAudit.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // 社保管理
  // 城市辖区维护
  {
    path: "/citySafeguard",
    name: "CitySafeguard",
    component: () =>
      import(
        /* webpackChunkName: "citySafeguard" */
        "../views/socialSecurityManagement/citySafeguard.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // 社保政策管理
  // 退休时间管理
  {
    path: "/retirementTimeManagement",
    name: "retirementTimeManagement",
    component: () =>
      import(
        /* webpackChunkName: "retirementTimeManagement" */
        "../views/socialSecurityPolicyManagement/retirementTimeManagement.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // 客户管理--客户信息
  // 折扣系数
  {
    path: "/discountFactor",
    name: "discountFactor",
    component: () =>
      import(
        /* webpackChunkName: "discountFactor" */
        "../views/customerManagement/customerInformation/discountFactor.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
