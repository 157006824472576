<template>
  <a-config-provider :locale="locale">
    <div
      class="bme-bm-layout"
      :class="{ 'bme-bm-layout-dev': environment == 'development' }"
    >
      <a-layout>
        <a-layout-header
          class="bme-bm-header"
          v-if="environment == 'development'"
          >Header</a-layout-header
        >
        <a-layout>
          <a-layout-sider
            width="215"
            class="bme-bm-aside"
            v-if="environment == 'development'"
          >
            <a-menu mode="inline">
              <my-menu :menuData="menuData"></my-menu>
            </a-menu>
          </a-layout-sider>
          <a-layout-content class="bme-bm-main">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
import { ref } from "vue";
import MyMenu from "@/components/myMenu.vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  components: {
    MyMenu,
  },
  setup() {
    const locale = ref(zhCN);
    const environment = ref(process.env.NODE_ENV);
    const menuData = ref([
      {
        key: "1",
        title: "结算管理",
        children: [
          {
            key: "1-1",
            title: "应收管理",
            children: [
              {
                key: "1-1-1",
                title: "已出结算单列表",
                to: "/issuedBill",
              },
              {
                key: "1-1-2",
                title: "账单审核列表",
                to: "/billAudit",
              },
            ],
          },
        ],
      },
      {
        key: "2",
        title: "社保管理",
        children: [
          {
            key: "2-1",
            title: "城市辖区维护",
            to: "/citySafeguard",
          },
        ],
      },
      {
        key: "3",
        title: "社保政策管理",
        children: [
          {
            key: "3-1",
            title: "退休时间管理",
            to: "/retirementTimeManagement",
          },
        ],
      },
      {
        key: "4",
        title: "客户管理",
        children: [
          {
            key: "4-1",
            title: "客户信息",
            children: [
              {
                key: "4-1-1",
                title: "折扣系数",
                to: "/discountFactor",
              },
            ],
          },
        ],
      },
    ]);
    return {
      environment,
      menuData,
      locale,
    };
  },
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Helvetica Neue, Helvetica, Arial, Segoe UI, PingFang SC, Source Han Sans SC, Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
.bme-bm-layout,
.bme-bm-layout-dev {
  .bme-bm-header {
    background: #001529;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
  }
  .bme-bm-aside {
    background: #fff;
    height: calc(100vh - 64px);
  }
  .bme-bm-main {
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    box-sizing: border-box;
    padding: 30px;
  }
}
</style>
