<template>
  <div>
    <template v-for="item in this.curData">
      <a-sub-menu
        :key="item.key"
        :title="item.title"
        v-if="item.children"
        @click="goTo(item.to)"
      >
        <my-menu :menuData="item.children"></my-menu>
      </a-sub-menu>
      <a-menu-item :key="item.key" v-else @click="goTo(item.to)">{{
        item.title
      }}</a-menu-item>
    </template>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "MyMenu",
  props: {
    menuData: {
      type: Array,
    },
  },
  setup(props) {
    const curData = ref(props.menuData);
    return {
      curData,
    };
  },
  methods: {
    goTo(to) {
      if (!to) return;
      this.$router.push(to);
    },
  },
};
</script>

<style></style>
